import React, { useState, useCallback } from "react"
import styled from "styled-components"
import { navigate } from "gatsby-link"
import {
  Layout,
  SEO,
  Wrapper,
  Typography,
  TextField,
  Grid,
  GridCell,
  GridRow,
  Footer,
  Button,
  HorizontalRule,
} from "../components"
import { breakpoints } from "../styles/breakpoints"

const GridMainContent = styled(Grid)`
  max-width: 800px;

  &.mdc-layout-grid {
    padding-top: var(--gutter-1);
    padding-bottom: 0;
  }

  @media (max-width: ${breakpoints.PHONE}) {
    &.mdc-layout-grid {
      padding-top: var(--gutter-3);
    }
  }
`

const FullWidthTextField = styled(TextField)`
  width: 100%;
`

const TypographyWithGutter = styled(props => <Typography {...props} />)`
  margin-bottom: var(--gutter-3);
  display: block;
`

const FormGridRow = styled(GridRow)`
  margin-top: var(--gutter-3);

  @media (max-width: ${breakpoints.PHONE}) {
    margin-top: var(--gutter-4);
  }
`

const ErrorContainer = styled.div`
  background-color: var(--status-warning);
  border-radius: 1.5rem;
  padding: 1rem;

  display: flex;
  align-items: center;
  justify-content: center;

  .mdc-typography--body1 {
    color: var(--text-color);
  }
`

const MessageTextArea = styled(TextField)`
  width: 100%;
  min-height: 180px;
`

const ActionButtonCell = styled(GridCell)`
  display: flex;
  justify-content: flex-end;

  @media (max-width: ${breakpoints.PHONE}) {
    justify-content: center;
  }
`

const AnchorLink = styled.a`
  -webkit-font-smoothing: antialiased;
  font-family: var(
    --mdc-typography-body1-font-family,
    var(--mdc-typography-font-family, Roboto, sans-serif)
  );
  font-size: var(--mdc-typography-body1-font-size);
  line-height: var(--mdc-typography-body1-line-height);
  font-weight: var(--mdc-typography-body1-font-weight);
  letter-spacing: var(--mdc-typography-body1-letter-spacing);
  text-transform: inherit;
  text-transform: var(--mdc-typography-body1-text-transform);
  text-align: underline;
  color: var(--theme-primary);
`

const AlignedHorizontalRule = styled(HorizontalRule)`
  margin-top: -29px;

  @media (max-width: ${breakpoints.PHONE}) {
    margin-top: -8px;
  }
`

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

const ContactPage = () => {
  // Used to store the values of the form
  const [formState, setFormState] = useState({
    botField: "",
    name: "",
    email: "",
    message: "",
  })
  // Used to store submitting and error state
  const [submitState, setSubmitState] = useState({})

  const handleSubmit = useCallback(
    async e => {
      e.preventDefault()
      setSubmitState({ submitting: true })
      const form = e.target

      try {
        await fetch("/", {
          method: "POST",
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
          body: encode({
            "form-name": form.getAttribute("name"),
            ...formState,
          }),
        })

        setSubmitState({})

        navigate("/message-sent")
      } catch (error) {
        setSubmitState({ error })
      }
    },
    [formState]
  )

  const handleChange = useCallback(e => {
    const { name, value } = e.target
    setFormState(prevState => ({ ...prevState, [name]: value }))
  }, [])

  return (
    <Layout page="/contact">
      <SEO title="Contact Us" />

      <Wrapper>
        <GridMainContent>
          <GridRow>
            <GridCell span={12}>
              <TypographyWithGutter use="headline2">
                Contact Us
              </TypographyWithGutter>
            </GridCell>
          </GridRow>
          <GridRow>
            <GridCell span={12}>
              <Typography use="body1">
                Please fill out the form, or look below for other ways to get in
                touch with us.
              </Typography>
            </GridCell>
          </GridRow>
          <GridRow>
            <GridCell span={12}>
              <Typography use="body1">
                We will get back to you as soon as possible.
              </Typography>
            </GridCell>
          </GridRow>
          <form
            name="contact"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
            onSubmit={handleSubmit}
          >
            <div hidden>
              <label>
                Don’t fill this out:
                <input
                  name="bot-field"
                  value={formState.botField}
                  onChange={handleChange}
                />
              </label>
            </div>
            <FormGridRow>
              <GridCell desktop={6} tablet={4} phone={4}>
                <FullWidthTextField
                  outlined
                  required
                  type="text"
                  label="Name"
                  name="name"
                  onChange={handleChange}
                  value={formState.name}
                />
              </GridCell>
              <GridCell desktop={6} tablet={4} phone={4}>
                <FullWidthTextField
                  outlined
                  required
                  type="email"
                  label="Email address"
                  name="email"
                  onChange={handleChange}
                  value={formState.email}
                />
              </GridCell>
            </FormGridRow>

            <FormGridRow>
              <GridCell span={12}>
                <MessageTextArea
                  textarea
                  outlined
                  required
                  label="Message"
                  name="message"
                  onChange={handleChange}
                  value={formState.message}
                />
              </GridCell>
            </FormGridRow>
            {submitState.error && (
              <FormGridRow>
                <GridCell span={12}>
                  <ErrorContainer>
                    <Typography use="body1">
                      Something went wrong, please try again.
                    </Typography>
                  </ErrorContainer>
                </GridCell>
              </FormGridRow>
            )}
            <FormGridRow>
              <ActionButtonCell span={12}>
                <Button
                  label={submitState.submitting ? "Sending..." : "Send"}
                  size="large"
                  type="submit"
                  disabled={submitState.submitting}
                />
              </ActionButtonCell>
            </FormGridRow>
          </form>
        </GridMainContent>
      </Wrapper>

      <Wrapper>
        <GridMainContent>
          <GridRow>
            <GridCell span={12}>
              <AlignedHorizontalRule />
            </GridCell>
          </GridRow>
          <GridRow>
            <GridCell span={12}>
              <Typography use="headline3">Other details</Typography>
            </GridCell>
          </GridRow>

          <FormGridRow>
            <GridCell span={4}>
              <Typography use="body1">Email: </Typography>
              <AnchorLink href="mailto:info@incept5.com">
                info@incept5.com
              </AnchorLink>
            </GridCell>
            <GridCell span={4}>
              <Typography use="body1">Twitter: </Typography>
              <AnchorLink href="https://twitter.com/incept5" target="_blank">
                @incept5
              </AnchorLink>
            </GridCell>
          </FormGridRow>
        </GridMainContent>
      </Wrapper>
      <Footer />
    </Layout>
  )
}

export default ContactPage
